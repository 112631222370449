import React from 'react';
import { Layout } from 'antd';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import './index.scss';
import LeftSiderMenu from './LeftSiderMenu';
import Header from './Header';
import {
  shouldHideUIElements,
  applyIframeMode,
  removeIframeMode,
  handleAutoLoginFromToken
} from '../../Utils/iframe';

const { Content, Footer } = Layout

const DashboardLayout = withRouter((props) => {
  const fetchMe = useStoreActions((actions) => actions.auth.fetchMe)
  const isMenuCollapsed = useStoreState((state) => state.sideMenu.isMenuCollapsed)
  const setMenuCollapse = useStoreActions((action) => action.sideMenu.setMenuCollapse)
  
  // Determine if we should hide UI elements using our utility function
  const hideUIElements = shouldHideUIElements();

  React.useEffect(() => {
    // Check for token in URL and handle auto-login if token is present
    const autoLoginAttempted = handleAutoLoginFromToken();
    if (autoLoginAttempted) {
      console.log('Auto login attempted via token in URL');
    }
    
    // Fetch user data
    fetchMe()
    
    // Apply iframe mode styles when component mounts
    applyIframeMode();
    
    // Clean up iframe mode styles when component unmounts
    return removeIframeMode;
  }, [fetchMe])

  return (
    <Layout id="dashboard_layout_contain" className={hideUIElements ? 'iframe-mode' : ''}>
      {!hideUIElements && (
        <LeftSiderMenu menuCollapse={isMenuCollapsed} setMenuCollapse={setMenuCollapse} />
      )}
      <Layout className={`site-layout-contain ${!isMenuCollapsed && !hideUIElements && 'menu-expanded'}`}>
        {!hideUIElements && <Header />}
        <Content className={`dashboard-layout-content ${hideUIElements ? 'iframe-content' : ''}`}>
          <div className="site-layout-wrapper">{props.children}</div>
        </Content>
        {/* <Footer className="dashboard-layout-footer" style={{ textAlign: 'center' }}>
          Kariba ©2021 Powered by Seedcom
        </Footer> */}
      </Layout>
    </Layout>
  )
})

// Check for token in URL before redirecting
const hasValidToken = () => {
  // Check localStorage for existing token
  const token = localStorage.getItem('Authorization');
  console.log('DashboardRoute - Checking token:', token ? 'Token exists' : 'No token found');
  
  if (token) return true;
  
  // If no token in localStorage, try to get from URL and process it
  console.log('DashboardRoute - Attempting auto login from URL token');
  const autoLoginSuccess = handleAutoLoginFromToken();
  console.log('DashboardRoute - Auto login result:', autoLoginSuccess);
  
  return autoLoginSuccess;
}

const DashboardRoute = ({ component: Component, ...rest }) => {
  if (!hasValidToken()) {
    console.log('DashboardRoute - No valid token, redirecting to login');
    return <Redirect to="/login" />
  }

  console.log('DashboardRoute - Valid token found, rendering component');
  return (
    <Route
      {...rest}
      render={(props) => (
        <DashboardLayout>
          <Component {...props} />
        </DashboardLayout>
      )}
    />
  )
}

export { DashboardLayout, DashboardRoute }
